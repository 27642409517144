<template>
  <v-layout column id="posts">
    <v-sheet class="px-5 mx-auto py-4 mw-1000" v-for="(p, i) in posts" :key="p.title">
      <ListItem :post="p" />
      <v-divider v-if="i != posts.length - 1" class="mt-5" />
    </v-sheet>

    <v-spacer />

    <v-sheet class="px-5 mx-auto py-4 mw-1000">
      <v-pagination
        v-model="page"
        color="secondary"
        :length="totalPages"
        :total-visible="totalVisible"
        @next="getPosts"
        @prev="getPosts"
      />
    </v-sheet>
  </v-layout>
</template>

<script>
import ListItem from './ListItem'
const TOTAL_VISIBLE_MAX = 7
export default {
  components: { ListItem },
  data: () => ({
    page: 1,
    pageSize: 5,
    totalVisible: 1,
    totalPages: 1,
    posts: []
  }),
  methods: {
    async getPosts() {
      this.$emit('isLoading', true)
      try {
        const response = await this.$prismic.client.query(
          this.$prismic.Predicates.at('document.type', 'blog_post'),
          {
            page: this.page,
            pageSize: this.pageSize,
            orderings: '[my.blog_post.date desc]'
          }
        )
        this.totalPages = response.total_pages
        this.totalVisible =
          this.totalPages > TOTAL_VISIBLE_MAX ? TOTAL_VISIBLE_MAX : this.totalPages
        this.posts = response.results.map(r => ({
          friendlyurl: r.data.friendlyurl,
          title: r.data.title[0].text,
          summary: r.data.summary[0].text,
          date: new Date(r.first_publication_date),
          tags: []
        }))
      } catch (error) {
        console.error(error)
      } finally {
        this.$emit('isLoading', false)
      }
    }
  },
  created() {
    this.getPosts()
  }
}
</script>

<style scoped>
#posts {
  min-height: 80vh;
}
.mw-1000 {
  max-width: 1000px;
}
</style>
