<template>
  <div>
    <h2 class="display-1 font-weight-light secondary--text mb-4">{{ post.title }}</h2>
    <p>
      <v-icon small class="mdi mdi-calendar"></v-icon>
      {{ post.date | toLocaleDateString }}
    </p>
    <p class="subheading">{{ post.summary }}</p>
    <p v-if="post.tags.length">
      <v-icon class="mdi mdi-tag-multiple" style="vertical-align: middle;"></v-icon>
      {{ post.tags.toString() }}
    </p>
    <v-btn :to="{name: 'blog-post', params: { friendlyurl: post.friendlyurl }}">Read more</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
      default() {
        return {
          friendlyurl: '',
          title: '',
          date: new Date(),
          summary: '',
          tags: []
        }
      }
    }
  },
  filters: {
    toLocaleDateString: d => d.toLocaleDateString()
  }
}
</script>

<style scoped>
.v-icon {
  vertical-align: inherit;
}
</style>
